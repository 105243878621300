import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container">
        <div
          className="content"
          style={{
            'text-align': 'center'
          }}
        >
          <h1>Thank you!</h1>
          <p>We’re still in private beta and working to open things up soon.</p>
        </div>
      </div>
    </section>
  </Layout>
)
